import type * as React from 'react';
export const DialpadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <circle
      cx={18}
      cy={4}
      r={2}
      fill="currentColor"
      transform="rotate(-90 18 4)"
    />
    <circle
      cx={18}
      cy={11}
      r={2}
      fill="currentColor"
      transform="rotate(-90 18 11)"
    />
    <circle
      cx={18}
      cy={18}
      r={2}
      fill="currentColor"
      transform="rotate(-90 18 18)"
    />
    <circle
      cx={11}
      cy={4}
      r={2}
      fill="currentColor"
      transform="rotate(-90 11 4)"
    />
    <circle
      cx={11}
      cy={11}
      r={2}
      fill="currentColor"
      transform="rotate(-90 11 11)"
    />
    <circle
      cx={11}
      cy={18}
      r={2}
      fill="currentColor"
      transform="rotate(-90 11 18)"
    />
    <circle
      cx={4}
      cy={4}
      r={2}
      fill="currentColor"
      transform="rotate(-90 4 4)"
    />
    <circle
      cx={4}
      cy={11}
      r={2}
      fill="currentColor"
      transform="rotate(-90 4 11)"
    />
    <circle
      cx={4}
      cy={18}
      r={2}
      fill="currentColor"
      transform="rotate(-90 4 18)"
    />
  </svg>
);
