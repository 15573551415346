import type * as React from 'react';
export const CartIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2 2c-.5523 0-1 .4477-1 1s.4477 1 1 1V2Zm0 2h2.1978V2H2v2Zm2.1978 0 1.826 8.2169 1.9524-.4338-1.826-8.217L4.1978 4Zm0 0 1.9524-.4339A2 2 0 0 0 4.1978 2v2Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M18 12H7.2812c-1.1045 0-2 .8954-2 2v0c0 1.1046.8955 2 2 2h11.75"
    />
    <circle
      cx={8}
      cy={18}
      r={2}
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
    />
    <circle
      cx={15.781}
      cy={18}
      r={2}
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M6 6h12.6126c.6825 0 1.1645.6687.9487 1.3162L18 12"
    />
  </svg>
);
