import type * as React from 'react';
export const TruckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.75 15H12V5c0-.5523-.4477-1-1-1H4c-.5523 0-1 .4477-1 1v9c0 .5523.4477 1 1 1h.3536M14 15h-2V7h3.1716a2 2 0 0 1 1.4142.5858l2.8284 2.8284A2 2 0 0 1 20 11.8284V15h-1"
    />
    <circle
      cx={2.5}
      cy={2.5}
      r={2.5}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      transform="matrix(-1 0 0 1 9 14)"
    />
    <circle
      cx={2.5}
      cy={2.5}
      r={2.5}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      transform="matrix(-1 0 0 1 19 14)"
    />
  </svg>
);
