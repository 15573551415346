import type * as React from 'react';
export const MoneyBagCheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 20H7c-2.5 0-5-1.5-5-6 0-3.5 2.5-8 7.5-8H11M11 20h4c2.5 0 5-1.5 5-6 0-3.5-2.5-8-7.5-8H11M9 2s0 4 2 4 2-4 2-4H9Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m8 13.5 2 2 4-4"
    />
  </svg>
);
