import type * as React from 'react';
export const QuestionIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 12v-1l1.2566-.4189c.9628-.3209 1.4832-1.3616 1.1623-2.3245A1.8378 1.8378 0 0 0 11.6754 7h-.4328a3 3 0 0 0-2.1213.8787L9 8"
    />
    <circle
      cx={11}
      cy={15}
      r={1.125}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.25}
    />
    <circle
      cx={11}
      cy={11}
      r={9}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);
