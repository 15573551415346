import type * as React from 'react';
export const FilterIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 12 3.5995 5.2494C2.552 3.9399 3.4842 2 5.1612 2h11.6775c1.6771 0 2.6094 1.9399 1.5618 3.2494L13 12v4.5a1 1 0 0 1-.4.8l-2 1.5c-.6592.4944-1.6.024-1.6-.8v-6Z"
    />
  </svg>
);
