import type * as React from 'react';
export const CloudIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2.057}
      d="M16.0047 8.9974a4.9964 4.9964 0 0 0-1.4644-3.5337 5.0015 5.0015 0 0 0-7.0711 0 4.996 4.996 0 0 0-1.4645 3.5337 4.5076 4.5076 0 0 0-3.1102 1.4905 4.503 4.503 0 0 0 .3465 6.3607 4.5085 4.5085 0 0 0 3.2537 1.1442h9a4.509 4.509 0 0 0 3.2567-1.1371 4.5036 4.5036 0 0 0 .3606-6.3607 4.508 4.508 0 0 0-3.1073-1.4976Z"
    />
  </svg>
);
