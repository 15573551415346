import type * as React from 'react';
export const LeversVerticalIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 19c1.6569 0 3-1.3431 3-3s-1.3431-3-3-3-3 1.3431-3 3 1.3431 3 3 3ZM16 3v10M6 3c1.6569 0 3 1.3431 3 3S7.6569 9 6 9 3 7.6569 3 6s1.3431-3 3-3ZM6 19V9"
    />
  </svg>
);
