import type * as React from 'react';
export const ChampagneIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.0001 14.71a3 3 0 0 1-1-4.12l4.12-6.12 5.12 3.12-4.12 6.12a3.0004 3.0004 0 0 1-4.12 1v0ZM9.96 14.71l-2.6631 4.1494M5.6262 17.875l3.3413 2.1293M9 11h6.6563"
    />
    <circle
      cx={3}
      cy={10}
      r={1.125}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.25}
    />
    <circle
      cx={6}
      cy={4}
      r={1.125}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.25}
    />
    <circle
      cx={19}
      cy={3}
      r={1.125}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.25}
    />
    <circle
      cx={19}
      cy={16}
      r={1.125}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.25}
    />
  </svg>
);
