import type * as React from 'react';
export const PaperFoldedIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 20h14a2 2 0 0 0 2-2V4c0-1.1046-.8954-2-2-2H8c-1.1046 0-2 .8954-2 2v14a2 2 0 0 1-2 2Zm0 0a1.9999 1.9999 0 0 1-2-2v-6a1 1 0 0 1 1-1h3M10 7h6M10 11h6M10 15h6"
    />
  </svg>
);
