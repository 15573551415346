import type * as React from 'react';
export const FoldingPaperIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2 5.5214a1 1 0 0 1 .7164-.959L8 3v14.2257l-4.7164 1.3947C2.6428 18.8099 2 18.3297 2 17.6615v-12.14ZM14 4.7743 8 3v14.2257L14 19V4.7743ZM14 4.7743l4.7164-1.3947C19.3572 3.19 20 3.6703 20 4.3386v12.14a.9998.9998 0 0 1-.7164.9589L14 19V4.7743Z"
    />
  </svg>
);
