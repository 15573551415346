import type * as React from 'react';
export const MultipleCardsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m16.7699 5.256-1.7912-2.9853c-.6794-1.1323-2.1223-1.541-3.2945-.9332L2.5534 6.072C1.303 6.7204.8348 8.2734 1.5185 9.505l.7816 1.4078"
    />
    <rect
      width={14}
      height={10}
      x={6}
      y={10}
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      rx={1.5}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.75 13.75h13.5v1.5H6.75z"
    />
  </svg>
);
