import type * as React from 'react';
export const BanknoteAltIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="m3.3368 19.1979-.2383.143C2.615 19.631 2 19.2828 2 18.719V5.2518c0-.1562.082-.301.2159-.3813l1.1209-.6726A8.8529 8.8529 0 0 1 11 3.5a8.8529 8.8529 0 0 0 7.6632-.698l.2383-.143C19.385 2.369 20 2.7173 20 3.281v13.4672c0 .1562-.082.301-.2159.3813l-1.1209.6726A8.8531 8.8531 0 0 1 11 18.5a8.8531 8.8531 0 0 0-7.6632.6979Z"
    />
    <circle
      cx={11}
      cy={11}
      r={2.25}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);
