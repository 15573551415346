import type * as React from 'react';
export const DotsMoreIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <circle cx={18} cy={11} r={2} fill="currentColor" />
    <circle cx={11} cy={11} r={2} fill="currentColor" />
    <circle cx={4} cy={11} r={2} fill="currentColor" />
  </svg>
);
