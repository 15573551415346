import type * as React from 'react';
export const SmallArrowLeftIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={8}
    height={13}
    fill="none"
    viewBox="0 0 8 13"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.5012 1.4976 1.5 6.4988 6.5012 11.5"
    />
  </svg>
);
