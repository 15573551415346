import type * as React from 'react';
export const PaperTearPercentIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m19 20-4-2-4 2-4-2-4 2V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v17ZM8 13l6-6"
    />
    <circle
      cx={8}
      cy={7}
      r={1.125}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.25}
    />
    <circle
      cx={14}
      cy={13}
      r={1.125}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.25}
    />
  </svg>
);
