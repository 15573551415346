import type * as React from 'react';
export const XRoundIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <circle cx={11} cy={11} r={8} fill="currentColor" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m8 14 6.0001-6M14.0001 14l-6-6"
    />
  </svg>
);
