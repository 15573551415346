import type * as React from 'react';
export const CloudStrikethroughOfflineIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.636 17.364 17.364 4.636M2.4185 13.5A3.413 3.413 0 0 1 2 11.8571c0-1.8935 1.535-3.4285 3.4286-3.4285a3.423 3.423 0 0 1 1.1484.197 3.4792 3.4792 0 0 1-.0056-.197C6.5714 6.535 8.1064 5 10 5c.3479 0 .6836.0518 1 .1481M11.5 15.9947h4.8159c.9137.0482 1.8106-.2432 2.4933-.81.6828-.5669 1.0954-1.3627 1.1472-2.2125.0518-.8498-.2616-1.6839-.8711-2.3188-.3591-.3741-.8037-.661-1.2953-.8435"
    />
  </svg>
);
