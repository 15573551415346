import type * as React from 'react';
export const DocumentArrowDownIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2 20V8.6568a4 4 0 0 1 1.1715-2.8284l2.6569-2.6568A4 4 0 0 1 8.6568 2H16c1.1045 0 2 .8954 2 2v16"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M7 7.5H3.6213L7.5 3.6213V7a.5.5 0 0 1-.5.5Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 20v-8M7 17l3 3 3-3"
    />
  </svg>
);
