import type * as React from 'react';
export const MicroCrownForBoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    fill="none"
    viewBox="0 0 14 14"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      d="M2 4v6.5h10V4L9.5 6 7 4 4.5 6 2 4Z"
    />
  </svg>
);
