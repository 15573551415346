import type * as React from 'react';
export const MicroClockMiniForLabelIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={14}
    height={14}
    fill="none"
    viewBox="0 0 14 14"
    aria-hidden="true"
    {...props}
  >
    <circle
      cx={7}
      cy={7}
      r={5}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.0003 4.5v2.8333L8.5 8.5"
    />
  </svg>
);
