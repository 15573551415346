import type * as React from 'react';
export const BusIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 4c0-1.1046.8954-2 2-2h12c1.1046 0 2 .8954 2 2v11c0 1.1046-.8954 2-2 2H5c-1.1046 0-2-.8954-2-2V4ZM3 11h16M11 10V3M5 17v3M17 17v3M14 14h1M7 14h1"
    />
  </svg>
);
