import type * as React from 'react';
export const NewViewIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m11 11 8-8M12 3h7v7M17 15.6167V17c0 1.1046-.8954 2-2 2H5c-1.1046 0-2-.8954-2-2V7c0-1.1046.8954-2 2-2h1.3833"
    />
  </svg>
);
