import type * as React from 'react';
export const CalendarEmptyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 4H3c-.5523 0-1 .4477-1 1v14c0 .5523.4477 1 1 1h16c.5523 0 1-.4477 1-1V5c0-.5523-.4477-1-1-1ZM6 2v4M16 2v4"
    />
    <path stroke="currentColor" strokeWidth={2} d="M3 10h16" />
  </svg>
);
