import type * as React from 'react';
export const UmbrellaIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 12.625V18c0 1.1046-.8954 2-2 2s-2-.8954-2-2v-.4375M11 3V2M19.8821 10.945c.0935.5681-.3679 1.055-.9437 1.055H3.0616c-.5758 0-1.0372-.4869-.9437-1.055C2.8616 6.4304 6.5553 3 11 3c4.4447 0 8.1384 3.4304 8.8821 7.945Z"
    />
  </svg>
);
