import type * as React from 'react';
export const ChatIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m5.25 16-2.362 2.907c-.2968.3653-.888.1554-.888-.3153V5C2 3.343 3.343 2 5 2h12c1.6568 0 3 1.3431 3 3v8c0 1.6568-1.3432 3-3 3H5.25ZM7 7h8M7 11h4"
    />
  </svg>
);
