import type * as React from 'react';
export const CarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 9H4c-1.1046 0-2 .8954-2 2v2c0 1.1046.8954 2 2 2h14c1.1046 0 2-.8954 2-2v-2c0-1.1046-.8954-2-2-2ZM5 16v2M17 16v2M5 12h1M16.6838 5.0513A3.0001 3.0001 0 0 0 13.8377 3H8.1623a3 3 0 0 0-2.846 2.0513L4 9h14l-1.3162-3.9487ZM16 12h1"
    />
  </svg>
);
