import type * as React from 'react';
export const PalmTreeHolidayIslandIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 20H4c.2336-2.233 3.2783-4 7-4s6.7664 1.767 7 4Z"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="bevel"
      strokeWidth={2}
      d="M11.1284 15.437C12.5 11 12 8 9.5 5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M8.8301 5.4143c-.6342.1365-1.2338.3893-1.7643.7439s-.9816.804-1.3276 1.3227c-.346.5187-.58 1.0964-.6887 1.7002A4.3956 4.3956 0 0 0 5.1045 11M8.519 4.2846a3.3377 3.3377 0 0 0-4.6346.8962"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="bevel"
      strokeWidth={2}
      d="M10.5941 5.1393s4.6328-.1633 5.2775 4.0597M10.0005 4.0918S11.5 1.5 15.022 3.0387"
    />
  </svg>
);
