import type * as React from 'react';
export const GlassesIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <circle
      cx={5.5}
      cy={15.5}
      r={3.5}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2 15.5V8l4-4"
    />
    <circle
      cx={3.5}
      cy={3.5}
      r={3.5}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      transform="matrix(-1 0 0 1 20 12)"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20 15.5V8l-4-4M7.5 13v0c2.014-1.7263 4.986-1.7263 7 0v0"
    />
  </svg>
);
