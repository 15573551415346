import type * as React from 'react';
export const MicroXMiniForInvoiceIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={10}
    height={10}
    fill="none"
    viewBox="0 0 10 10"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m2 8 6-6M8 8 2 2"
    />
  </svg>
);
