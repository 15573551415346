import type * as React from 'react';
export const PaperPlaneIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path stroke="currentColor" strokeWidth={2} d="M7.5 13.5962 19 4" />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.5 3.5 2 10.0962l11.5 7.5L19.5 3.5Z"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 13v6.5l4-4"
    />
  </svg>
);
