import type * as React from 'react';
export const LegalParagraphIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 20H5c-1.1046 0-2-.8954-2-2V8.6569a4 4 0 0 1 1.1716-2.8285l2.6568-2.6568A4 4 0 0 1 9.657 2H17c1.1046 0 2 .8954 2 2v1"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 8H4.0227L9 2.75V8Z"
    />
    <path
      fill="currentColor"
      d="M17.9288 10.7502c.5035.2269 1.0956.0026 1.3225-.5009.2269-.5035.0027-1.0956-.5008-1.3225l-.8217 1.8234Zm-.4099-1.2815.4109-.9118-.4109.9117Zm-3.1914.0288.4273.9042-.4273-.9042Zm-.12.0568-.4273-.9042.4273.9042Zm.2146 2.953-.2921.9564.2921-.9564Zm3.255.994-.2921.9564.2921-.9564Zm.9975 2.3032-.8974-.4413.8974.4413Zm-.0821.1669.8974.4413-.8974-.4413Zm.0892-1.6066.9017-.4323-.9017.4323Zm-5.2947-3.8983.9442.3294-.9442-.3294Zm5.3635-1.5397-.8207-.3699-.8217 1.8235.8207.3698.8217-1.8234Zm-4.8503-.3334-.12.0567.8546 1.8083.12-.0567-.8546-1.8083Zm.2298 4.8703 3.255.994.5842-1.9128-3.255-.994-.5842 1.9128Zm3.6472 1.8995-.0821.1669 1.7948.8826.082-.1669-1.7947-.8826Zm.0028-.566a.6477.6477 0 0 1-.0028.566l1.7947.8826a2.6479 2.6479 0 0 0 .0115-2.3133l-1.8034.8647Zm-.247 3.1816c.5674-.1188 1.0064-.4189 1.3115-.7075.3024-.2862.5208-.6054.6454-.8586l-1.7948-.8826c-.0222.0452-.1.17-.2252.2884-.1224.1159-.2421.1808-.3466.2027l.4097 1.9576Zm-.148-3.5211a.6479.6479 0 0 1 .395.3395l1.8034-.8647a2.6476 2.6476 0 0 0-1.6142-1.3876l-.5842 1.9128Zm-4.9422-4.3205c-.4837 1.3867.2826 2.8975 1.6872 3.3265l.5842-1.9128a.5873.5873 0 0 1-.383-.755l-1.8884-.6587Zm1.3374-1.487a2.587 2.587 0 0 0-1.3374 1.487l1.8884.6587a.5869.5869 0 0 1 .3036-.3375l-.8546-1.8083Zm4.1496-.0933a4.808 4.808 0 0 0-4.0296.0365l.8546 1.8083a2.8074 2.8074 0 0 1 2.3533-.0213l.8217-1.8235Z"
    />
    <path
      fill="currentColor"
      d="M14.0887 18.8873c-.4937-.2475-1.0946-.0479-1.3421.4458-.2475.4937-.0479 1.0946.4458 1.3421l.8963-1.7879Zm.1903 1.214-.4482.894.0185.0092.0189.0085.4108-.9117Zm3.1914-.0289-.4273-.9041.4273.9041Zm.12-.0567.4273.9041-.4273-.9041Zm-.2146-2.953.2921-.9564-.2921.9564Zm-3.255-.994.2921-.9564-.2921.9564Zm-.9975-2.3032.8974.4413v-.0001l-.8974-.4412Zm.0821-.167-.8973-.4412.8973.4412Zm-.0892 1.6066-.9017.4324.9017-.4324Zm5.2947 3.8983-.9442-.3293.9442.3293Zm-5.2185 1.5718.6384.3201.8963-1.788-.6384-.32-.8963 1.7879Zm4.7053.3013.12-.0567-.8546-1.8082-.12.0567.8546 1.8082Zm-.2298-4.8702-3.255-.994-.5842 1.9128 3.255.994.5842-1.9128Zm-3.6472-1.8996.0821-.1669-1.7947-.8825-.0821.1669 1.7947.8825Zm-.0028.5661a.6477.6477 0 0 1 .0028-.566l-1.7947-.8826a2.6479 2.6479 0 0 0-.0115 2.3133l1.8034-.8647Zm.247-3.1816c-.5673.1187-1.0064.4188-1.3115.7075-.3024.2861-.5208.6053-.6453.8586l1.7947.8825c.0222-.0452.1001-.17.2252-.2884.1224-.1158.2421-.1808.3467-.2027l-.4098-1.9575Zm.148 3.5211a.6482.6482 0 0 1-.395-.3395l-1.8034.8647a2.6482 2.6482 0 0 0 1.6142 1.3876l.5842-1.9128Zm4.9422 4.3204c.4837-1.3867-.2826-2.8975-1.6872-3.3264l-.5842 1.9128a.5872.5872 0 0 1 .383.755l1.8884.6586Zm-1.3374 1.4871a2.587 2.587 0 0 0 1.3374-1.4871l-1.8884-.6586a.5875.5875 0 0 1-.3036.3375l.8546 1.8082Zm-4.1495.0932a4.8078 4.8078 0 0 0 4.0295-.0365l-.8546-1.8082a2.8082 2.8082 0 0 1-2.3533.0213l-.8216 1.8234Z"
    />
  </svg>
);
