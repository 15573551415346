import type * as React from 'react';
export const EuroIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path fill="currentColor" d="M3 8h10v2H3zM3 12h9v2H3z" />
    <path
      fill="currentColor"
      d="M16.7982 8.646c-.2468-1.2541-.7045-2.105-1.3396-2.6541C14.8371 5.4543 13.8078 5 12 5c-1.4775 0-2.6759.5417-3.5201 1.4703C7.6252 7.4105 7 8.8994 7 11c0 2.1006.6252 3.5895 1.4799 4.5297C9.324 16.4583 10.5225 17 12 17c2.2146 0 3.3016-.7037 3.9193-1.5355.4464-.6012.7619-1.4223.9326-2.4617l1.9524.4486C18.2383 16.6705 16.3548 19 12 19c-4 0-7-3-7-8s3-8 7-8c4.3017 0 6.1565 2.191 6.7605 5.2597l-1.9623.3862Z"
    />
  </svg>
);
