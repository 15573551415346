import type * as React from 'react';
export const PersonPercentIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m14 20 5-5"
    />
    <circle
      cx={14}
      cy={15}
      r={1.125}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.25}
    />
    <circle
      cx={19}
      cy={20}
      r={1.125}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.25}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 10c2.2091 0 4-1.7909 4-4 0-2.2091-1.7909-4-4-4-2.2091 0-4 1.7909-4 4 0 2.2091 1.7909 4 4 4ZM10 16l-3-3h-.08A3.9201 3.9201 0 0 0 3 16.92V20h7"
    />
  </svg>
);
