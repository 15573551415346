import type * as React from 'react';
export const ArrowUpIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 18.5v-13m6.5012 5.0012L11 4l-6.5012 6.5012"
    />
  </svg>
);
