import type * as React from 'react';
export const SmallNewViewFor200Icon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 18 18"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m9 9 6-6M10 2h6v6M13 13.3417V14c0 1.1046-.8954 2-2 2H4c-1.1046 0-2-.8954-2-2V7c0-1.1046.8954-2 2-2h.6583"
    />
  </svg>
);
