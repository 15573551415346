import type * as React from 'react';
export const UnknownCleanIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.3284 12.3003a.911.911 0 0 0 .9111.911h.4141a.911.911 0 0 0 .911-.911c0-.5797.2484-.5301 1.3251-1.2092 1.3251-.8116 1.8883-1.6233 1.8883-2.7662 0-1.8551-1.6564-3.2465-3.9257-3.2465-1.999 0-3.1122.7525-3.486 1.9938-.1794.5954.3003 1.1414.9121 1.2527.6218.1283 1.0315-.5482 1.5863-.857.2057-.1144.5144-.1534.9711-.1534.911 0 1.4907.4141 1.4907 1.0933 0 .5466-.3147.8944-1.3748 1.5404-1.1926.7123-1.7116.6515-1.6233 2.3521Zm-.1656 3.3376c0 .709.5747 1.2837 1.2837 1.2837h.0828c.709 0 1.2837-.5747 1.2837-1.2837 0-.7089-.5747-1.2837-1.2837-1.2837h-.0828c-.709 0-1.2837.5748-1.2837 1.2837Z"
    />
  </svg>
);
