import type * as React from 'react';
export const SmallArrowDownIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={8}
    fill="none"
    viewBox="0 0 13 8"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m1.5 1.5 5.0012 5.0012L11.5024 1.5"
    />
  </svg>
);
