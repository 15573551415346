import type * as React from 'react';
export const TombstoneIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M17 18V8c0-2.2091-1.7909-4-4-4H9C6.7909 4 5 5.7909 5 8v10"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M3 18h16"
    />
    <path stroke="currentColor" strokeWidth={2} d="M11 7v8M8 10h6" />
  </svg>
);
