import type * as React from 'react';
export const RobotIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      d="m3.5343 13.9455.997-.0767a1 1 0 0 0-.765-.896l-.232.9727Zm.2526 3.2846-.997.0767.997-.0767Zm14.4262 0-.9971-.0767.9971.0767Zm.2708-3.5206-.3997-.9166a1 1 0 0 0-.5974.8399l.9971.0767Zm.2759-3.6453-.9998-.0225a1 1 0 0 0 .4662.8683l.5336-.8458Zm-15.5165.0839.3786.9256a1 1 0 0 0 .6201-.9756l-.9987.05Zm.523 2.8247A1.0009 1.0009 0 0 1 3 12H1c0 1.4176.9823 2.6034 2.3023 2.9182l.464-1.9454Zm1.0177 4.1806-.2527-3.2846-1.994.1534.2526 3.2846 1.994-.1534ZM6.778 19c-1.045 0-1.9139-.8046-1.994-1.8466l-1.9941.1534C2.9502 19.3908 4.6879 21 6.778 21v-2Zm8.4439 0H6.7781v2h8.4438v-2Zm1.9941-1.8466C17.1359 18.1954 16.267 19 15.2219 19v2c2.0902 0 3.8279-1.6092 3.9882-3.6932l-1.9941-.1534Zm.2708-3.5206-.2708 3.5206 1.9941.1534.2708-3.5206-1.9941-.1534Zm1.2387-1.819c0 .4363-.262.8137-.6413.9791l.7994 1.8333c1.0822-.4719 1.8419-1.5523 1.8419-2.8124h-2Zm-.4993-.9038c.3022.1906.4993.5246.4993.9038h2c0-1.0943-.5738-2.0539-1.4322-2.5953L18.2262 10.91ZM15.7604 8c1.1274 0 2.0246.9295 1.9996 2.0417l1.9995.045C19.8096 7.8591 18.0165 6 15.7604 6v2ZM6.2396 8h9.5208V6H6.2396v2ZM4.242 10.0981C4.1851 8.9609 5.0931 8 6.2396 8V6c-2.2936 0-4.109 1.9217-3.995 4.1981l1.9974-.1ZM3 12c0-.4168.2553-.7764.6219-.9263l-.7572-1.8511C1.7725 9.6693 1 10.7433 1 12h2Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 7 7 4M13 7l2-3"
    />
    <circle
      cx={0.75}
      cy={0.75}
      r={0.75}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      transform="scale(-1 1) rotate(-30 -2.438 31.945)"
    />
    <circle
      cx={0.75}
      cy={0.75}
      r={0.75}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      transform="scale(-1 1) rotate(-30 1.837 15.992)"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 16.5V16M11 16.5V16M14.0112 16.5V16"
    />
    <circle cx={8.5} cy={11.5} r={1.5} fill="currentColor" />
    <circle cx={13.5} cy={11.5} r={1.5} fill="currentColor" />
  </svg>
);
