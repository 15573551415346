import type * as React from 'react';
export const BuildingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.0001 20h-9c-.5523 0-1-.4477-1-1V3c0-.5523.4477-1 1-1h11c.5523 0 1 .4477 1 1v5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 8h-6c-.5523 0-1 .4477-1 1v10c0 .5523.4477 1 1 1h6c.5523 0 1-.4477 1-1V9c0-.5523-.4477-1-1-1Z"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14 12h2M14 16h2M5.0001 6h2M5.0001 10h2M5.0001 14h2"
    />
  </svg>
);
