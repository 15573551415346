import type * as React from 'react';
export const PaperTearAddIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.003}
      d="M16.0001 14.5V19l-3-1.5-3 1.5-3-1.5L4 19V6.7206c0-.1911.079-.3744.2196-.5096A.7658.7658 0 0 1 4.7501 6H7.5M12.2622 6h7.5M16.0001 9.7379v-7.5"
    />
  </svg>
);
