import type * as React from 'react';
export const ArrowLeftIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 11H5M9.8445 17.5012 3.3433 11l6.5012-6.5012"
    />
  </svg>
);
