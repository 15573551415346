import type * as React from 'react';
export const TextBullet300Icon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <circle cx={11} cy={11} r={10} fill="currentColor" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="m6.625 11 3.2813 3.125 5.4687-6.25"
    />
  </svg>
);
