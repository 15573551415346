import type * as React from 'react';
export const WarningIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 11.1514v-3.5M8.4216 4c1.146-2 4.0109-2 5.1568 0l6.0163 10.5c1.1459 2-.2865 4.5-2.5784 4.5H4.9838c-2.292 0-3.7244-2.5-2.5784-4.5L8.4216 4Z"
    />
    <circle
      cx={1.3}
      cy={1.3}
      r={1.3}
      fill="currentColor"
      transform="matrix(1 0 0 -1 9.688 16.151)"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 11.1514v-3.5"
    />
    <circle
      cx={1.3}
      cy={1.3}
      r={1.3}
      fill="currentColor"
      transform="matrix(1 0 0 -1 9.688 16.151)"
    />
  </svg>
);
