import type * as React from 'react';
export const ScalesIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M11 3.4375v14.0819M7.288 18.5625h7.4242M5.0005 3h12.0852"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m20.3879 9-3.051-6-3.0511 6M14.2858 9c0 1.6851 1.366 3.0511 3.0511 3.0511 1.685 0 3.051-1.366 3.051-3.0511M1.612 9l3.0511-6 3.0511 6M7.7142 9c0 1.6851-1.366 3.0511-3.051 3.0511-1.6851 0-3.0511-1.366-3.0511-3.0511"
    />
  </svg>
);
