import type * as React from 'react';
export const ScreamIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 8.838C3 5.0615 6.5817 2 11 2s8 3.0615 8 6.838V9M3 9c0 1.0412.5198 2.5452 1 3.5.652 1.2966 2.078 1.6561 3 3.5.5 1 2.0474 3 4 3s3.5-2 4-3c.922-1.8439 2.3512-2.2194 3-3.5.4901-.9674 1-2.4438 1-3.5"
    />
    <circle cx={8.5} cy={8.5} r={1.5} fill="currentColor" />
    <circle cx={8.5} cy={8.5} r={1.5} stroke="currentColor" />
    <circle cx={13.5} cy={8.5} r={1.5} fill="currentColor" />
    <circle cx={13.5} cy={8.5} r={1.5} stroke="currentColor" />
    <path
      fill="currentColor"
      d="M9 13.5c0-1.1046.8955-2.5 2-2.5 1.1046 0 2 1.3954 2 2.5 0 .5522-.4476 1-.9998 1H10c-.5522 0-1-.4478-1-1Z"
    />
  </svg>
);
