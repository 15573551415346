import type * as React from 'react';
export const BananastandIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m20.0003 9-2.4472-4.8944A2 2 0 0 0 15.7643 3h-9.528a2 2 0 0 0-1.7888 1.1056L2.0002 9M4.9993 12v5c0 1.1046.8954 2 2 2h8.0001c1.1045 0 2-.8954 2-2v-5M2 9.25c0 1.2426 1.0074 2.2499 2.25 2.2499 1.2427 0 2.25-1.0073 2.25-2.25 0 1.2427 1.0074 2.25 2.25 2.25 1.2427 0 2.2501-1.0073 2.2501-2.25 0 1.2427 1.0073 2.25 2.25 2.25 1.2426 0 2.25-1.0073 2.25-2.25 0 1.2427 1.0073 2.25 2.25 2.25 1.2426 0 2.25-1.0073 2.25-2.25"
    />
  </svg>
);
