import type * as React from 'react';
export const ArrowsAroundIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M4 9c1.0296-2.913 3.8077-5 7.0732-5 3.2656 0 6.0437 2.087 7.0733 5m0 5c-1.0296 2.913-3.8077 5-7.0733 5C7.8077 19 5.0296 16.913 4 14"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m18.1487 13.989-2.2038 1.1803 2.7198 1.2659-.516-2.4462ZM3.9907 9.0097l2.2038-1.1803-2.7198-1.266.516 2.4463Z"
    />
  </svg>
);
