import type * as React from 'react';
export const SmallArrowUpIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={8}
    fill="none"
    viewBox="0 0 13 8"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.5024 6.5012 6.5012 1.5 1.5 6.5012"
    />
  </svg>
);
