import type * as React from 'react';
export const PowerOffIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.3386 6.6563c1.0346 1.3353 1.6504 3.0114 1.6504 4.8314 0 4.3604-3.5348 7.8953-7.8953 7.8953-4.3604 0-7.8952-3.5349-7.8952-7.8953 0-1.82.6158-3.4961 1.6504-4.8315M11.0938 2.125v7.4375"
    />
  </svg>
);
