import type * as React from 'react';
export const PercentIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m8 14 6-6"
    />
    <circle
      cx={8}
      cy={8}
      r={1.125}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.25}
    />
    <circle
      cx={14}
      cy={14}
      r={1.125}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.25}
    />
    <circle
      cx={11}
      cy={11}
      r={9}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);
