import type * as React from 'react';
export const DiamondIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m2 8 3-5h12l3 5-8.2 10.9333c-.4.5334-1.2.5334-1.6 0L2 8ZM3 8h15.5165"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 3 7.5 8 11 19M13 3l1.5 5L11 19"
    />
  </svg>
);
